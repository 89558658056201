<template>
<div>
    <div class="noticebar">
    <div class="notice-img">
      <img class="notice-img" src="@/assets/images/header/announcement.png" alt="" />
    </div>
    <div style="width:70px;color:#fff;font-size: 14px;">公告:</div>
    <ul class="notice-list">
      <li 
        v-for="(item, index) in announces" 
        :key="item.index" 
        :class="!index && play ? 'toUp' : ''" 
        @click="gonggao(item.article_id)"
      >
      <div class="xinxi">
          <span>{{ item.article_name }}</span>
          <span>{{ item.createdAt.slice(0,10) }}</span>
      </div>       
      </li>
    </ul>
  </div>
</div>
  
</template>
 
<script>
export default {
  name: "noticeBar",
  props: {
    announces: {
      type: Array,
      default() {
        return [
        // { title: '这是第一条信息',time:'2021-10-12' },
        // { title: '这是第二条信息',time:'2021-10-12' },
        // { title: '这是第三条信息',time:'2021-10-12' },
        // { title: '这是第四条信息',time:'2021-10-12' },
        // { title: '这是第五条信息',time:'2021-10-12' },
        // { title: '这是第六条信息',time:'2021-10-12' },
        // { title: '这是第七条信息',time:'2021-10-12' },
        // { title: '这是第八条信息',time:'2021-10-12' },
        // { title: '这是第九条信息',time:'2021-10-12' },
        // { title: '这是第十条信息',time:'2021-10-12' }
        ];
      },
    },
  },
  data() {
    return {
      play: false, // 是否开始播放
    };
  },
  mounted() {
    this.selectAritcle()
    setInterval(this.startPlay, 3000);
  },
  methods: {
    gonggao(id){
      this.$router.push({
          path: "/news/detail",
          query: {id:id}
      });
    },
    selectAritcle(){
      this.$api.getChannelArticleByTypeIdApi({
        channelId:this.$http.channelId,
        typeCode: '0002',
        token:localStorage.getItem("pc_token")
      }).then(res => {
        this.announces = res.data.data
      })
    },
    // 控制文案轮播
    startPlay() {
      let that = this;
      that.play = true; //开始播放
      setTimeout(() => {
        that.announces.push(that.announces[0]); //将第一条数据塞到最后一个
        that.announces.shift(); //删除第一条数据
        that.play = false; //暂停播放
      }, 500);
    },
  },
};
</script>
 
<style lang="scss" scoped>
.noticebar {
  width: 100%;
  background: rgba(0,0,0,.2);
  padding: 10px 8%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.toUp {
  transform: translateY(-20px);
  transition: all 0.5s linear;
}
.notice-list {
  display: block;
  list-style: none;
  width: 100%;
  overflow: hidden;
  height: 20px;
  color: #fff;
  cursor: pointer;
  .xinxi{
      font-size: 14px;
      display: flex;
      justify-content: space-between;
  }
}
.notice-list li {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.notice-img {
//   width: 0.72rem;
  margin-right: 5px;
}
.img-right {
  width: 0.16rem;
}
li {
  height: 20px;
  line-height: 20px;
}
</style>
 
 